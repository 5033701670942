.Login {
  animation: fade-in 5s;
  background-color: #1667ae;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wipe-button {
  position: absolute;
  bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
  pointer-events: all;
}

img.login-icon {
  border-radius: 100%;
  /* margin-bottom: 10px; */
  width: 75px;
  height: 75px;
}

.login-username {
  color: white;
  font-weight: 600;
  margin: 10px 0px 20px 0px;
}

.login-label {
  font-size: small;
  margin-bottom: 5px;
  color: white;
}

.login-button {
  margin: 5px;
  margin-top: 20px;
  width: fit-content;
}

.login-fields {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: flex-start; */
}

.Login .window {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 2;
}

.App .title-bar,
.App .window,
.App button,
.App input,
.App label,
.App option,
.App select,
.App table,
.App textarea,
.App ul.tree-view {
  animation: none;
  -webkit-font-smoothing: none;
  font-family: 'director';
}

button.wipe-button {
  animation: fade-in 5s;
}

.window-photo {
  width: 326px;
}

.window-folder-photo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -200px;
  z-index: 2;
}
