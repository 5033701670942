@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,200&family=Xanh+Mono:ital@0;1&display=swap');

.Desktop {
  animation: fade-in 5s;
  background-image: url('../assets/desktop.png');
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.logout-button {
  animation: fade-in 6s;
  font-weight: 800;
  position: absolute;
  bottom: 0;
  margin: 3px;
  height: 30px;
  right: 120px;
  box-shadow: -6px -7px 7px -1px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: -6px -7px 7px -1px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: -6px -7px 7px -1px rgba(0, 0, 0, 0.75) inset;
  box-shadow: 6px 5px 7px -1px rgba(255, 255, 255, 0.75) inset;
  -webkit-box-shadow: 6px 5px 7px -1px rgba(255, 255, 255, 0.75) inset;
  -moz-box-shadow: 6px 5px 7px -1px rgba(255, 255, 255, 0.75) inset;
}

.top-window {
  position: relative;
  z-index: 8;
}

.text-file {
  z-index: 8;
  /* position: absolute;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; */
  height: 467px;
  width: 450px;
  background-image: url(../assets/text_file.png);
}

.text-file-folder {
  z-index: 8;
  position: absolute;
  top: -250px;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 467px;
  width: 450px;
  background-image: url(../assets/text_file.png);
}

.img-file-folder {
  z-index: 8;
  position: absolute;
  top: -250px;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-image: url(../assets/text_file.png);
}

.text-file-content {
  font-family: 'Xanh Mono', monospace;
  font-weight: 400;
  font-style: normal;
  position: absolute;
  margin: 8px;
  padding: 10px;
  top: 20px;
  left: 0px;
  width: 415px;
  height: 390px;
  background-color: white;
  font-size: small;
  overflow: scroll;
  text-align: left;
}
