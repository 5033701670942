.Icon {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: antiquewhite;
  font-size: small;
  text-align: center;
  width: 70px;
}

.Icon img {
  width: 35px;
  margin: 10px;
}

.icon-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow-wrap: break-word;
  max-width: 75px;
  display: inline-block;
}
