.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}

html {
  background-color: black;
  transition: 0.5s ease;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
  scroll-behavior: inherit;
}

button {
  animation: fade-in 5s;
  font-family: 'director';
  cursor: pointer;
}

.sticky-note {
  animation: fade-in 1s fade-out 1s;
  position: absolute;
  left: 0;
  right: 0;
  top: -150px;
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  transition: transform 0.5s ease-in-out;
  width: 350px;
  height: 350px;
  z-index: 15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sticky-note img {
  position: absolute;
  width: 350px;
  height: 350px;
  /* box-shadow: 0 0 25px rgba(0, 0, 0, 0.2); */
}

.sticky-note:hover {
  transform: rotate(2deg);
}

.sticky-title {
  width: 200px;
}

.game-title {
  font-weight: 500;
  font-size: larger;
  margin-bottom: 20px;
  font-family: 'resistance';
  /* text-transform: ; */
}

.game-subtitle {
  /* font-size: ; */

  font-family: 'argent-italics';
  /* text-transform: uppercase; */
}

.main-screen {
  animation: fade-in 5s;
  /* position: absolute; */
  position: relative;
  width: 900px;
  height: 650px;
  background-color: black;
  /* left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto; */
  overflow: hidden;
  cursor: url('assets/cursor.png'), auto;
}

#screen-tex {
  mix-blend-mode: hard-light;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 120%;

  opacity: 0.4;
  pointer-events: none;
}

#halftone-tex {
  mix-blend-mode: color-burn;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 120%;

  opacity: 0.2;
  pointer-events: none;
}

.flavour {
  animation: fade-in 5s;
  margin-top: 20px;
  height: 100px;
  width: 850px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
