.finder-window {
  width: 450px;
  height: 360px;
  background-image: url(../assets/finder.png);
  position: relative;
  z-index: 2;
}

.finder-name-topbar {
  position: absolute;
  top: 8px;
  left: 32px;
  color: antiquewhite;
  font-size: small;
}

.finder-name-address {
  position: absolute;
  top: 80px;
  left: 89px;
  color: black;
  font-size: small;
}

/* .Finder {
  position: relative;
  height: 600px;
  width: 900px;
  display: block;
  pointer-events: none;
  z-index: 2;
} */

.close-finder-button {
  position: absolute;
  top: 7px;
  right: 7px;
  width: 17px;
  height: 16px;

  cursor: pointer;
}

.close-finder-button:hover {
  background-color: rgba(0, 0, 0, 0.109);
  border: solid 1px black;
}
